(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:ProgrammeSports
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('ProgrammeSports', ProgrammeSports);

  function ProgrammeSports($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/programmes/:id/sports', {id: '@_id'});
  }
}());
